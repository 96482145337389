@use '@angular/material' as mat;
@use './colours' as colours;
@use './typography' as typography;
@use './mixins' as mixins;

// Material Theme Config
$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: colours.$primary,
      accent: colours.$accent,
      warn: colours.$warn,
    ),
    typography: typography.$typography,
    density: -1,
  )
);
$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: colours.$primary,
      accent: colours.$accent,
      warn: colours.$warn,
    ),
    typography: typography.$typography,
    density: -1,
  )
);

@mixin principle-material-theme {
  @include mat.core();

  // Include theme styles for core and each component used in your app.
  // Alternatively, you can import and @include the theme mixins for each component
  // that you are using.
  @include mat.all-component-themes($light-theme);
  .dark-theme {
    @include mat.all-component-colors($dark-theme);
  }

  @include mixins.breadcrumbs-theme($light-theme);

  @include mat.list-density(-2);
  @include mat.icon-density(-2);
  @include mat.chips-density(-2);
  @include mat.form-field-density(-3);
  @include mat.input-density(-3);
}

@mixin bg-gradient() {
  background-color: mat.m2-get-color-from-palette(colours.$accent, 700);
  background: linear-gradient(
    to right top,
    mat.m2-get-color-from-palette(colours.$accent, 800) 0%,
    mat.m2-get-color-from-palette(colours.$accent, 600) 40%,
    mat.m2-get-color-from-palette(colours.$accent, 600) 60%,
    mat.m2-get-color-from-palette(colours.$accent, 800) 100%
  );
}
