@use 'sass:map';
@use './theme' as theme;
@use './apply' as apply;
@use 'scss-shared' as shared;

@include apply.apply-theme();
@include shared.root-body-fix();

html {
  min-height: 100%;
  display: flex;
  @include theme.bg-gradient();
  background-color: map.get(theme.$accent, 700);
}

body {
  flex: 1;
  max-width: 100%;
  @include theme.bg-gradient();
}
