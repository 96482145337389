@use 'sass:math';
@use 'sass:map';
@use '@angular/material' as mat;
@use 'scss-shared' as shared;
@use './colours' as colours;
@use './typography' as typography;
@use './variables' as variables;

$size: subtitle-1;
$legend-size: mat.m2-font-size(typography.$typography, $size);
$readonly-color: #333;

@mixin json-schema-form {
  json-schema-form {
    display: block;

    .legend {
      @include mat.m2-typography-level(typography.$typography, $size);
      display: flex;
      padding-top: math.div($legend-size, 2);
      padding-bottom: math.div($legend-size, 2);
    }

    fieldset {
      legend.legend {
        position: relative;
        top: $legend-size;
        margin-bottom: $legend-size;
        padding-inline-start: 0;
        padding-inline-end: 0;
      }
    }

    material-design-framework {
      & > div {
        height: 100%;
      }
      material-checkbox-widget {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        .checkbox-name {
          white-space: normal !important;
        }
      }
    }

    .toolbar-legend > .legend {
      @include mat.m2-typography-level(typography.$typography, headline-6);
      padding: 0 shared.$mat-margin;
      height: 64px;
      background: whitesmoke;
      box-sizing: border-box;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
    }

    .form-flex-row {
      > .form-flex-item {
        flex: 1 1 200px !important;
        &:not(:last-child) {
          margin-right: shared.$mat-margin !important;
        }
      }
    }

    &.readonly {
      input:disabled,
      .mat-mdc-input-element:disabled {
        color: $readonly-color;
      }
      .mat-checkbox-disabled {
        &.mat-checkbox-checked .mat-checkbox-background,
        &.mat-checkbox-indeterminate .mat-checkbox-background {
          background-color: map.get(colours.$primary, 500);
        }
        .mat-checkbox-label {
          color: $readonly-color;
        }
      }
      .mat-select-disabled {
        .mat-select-value {
          color: $readonly-color;
        }
      }
    }

    .form-builder {
      &-column > flex-layout-root-widget {
        display: flex !important;
        flex-direction: column !important;
        gap: 4px !important;
        min-width: 300px;
      }

      &-row > flex-layout-root-widget {
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        gap: 16px !important;
        & > * {
          flex: 1 1 0px !important;
        }
      }
    }
  }
}
