@use 'scss-shared' as shared;

@mixin treatment {
  .step-container {
    display: block;
    padding: 8px;
    background-color: #eef3ff;
    @include shared.border-radius(6px);
  }
}
