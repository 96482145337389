@media print {
  html {
    background-color: #fafafa !important;
    background: #fafafa !important;
  }

  body {
    zoom: 1.2;
    background-color: #fafafa !important;
    background: #fafafa !important;
  }

  nav,
  pr-toolbar,
  pt-breadcrumbs,
  mat-sidenav,
  pr-patient-profile-header,
  .toolbar-spacer {
    display: none !important;
  }

  mat-drawer {
    visibility: hidden !important;
  }

  mat-sidenav-content {
    margin-left: 0 !important;
  }

  .mat-drawer-container,
  .mat-drawer-content {
    height: auto !important;
    overflow: visible !important;
  }
}
