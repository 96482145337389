@use '@angular/material' as mat;
@use 'scss-shared' as shared;
@use './theme' as theme;

@mixin apply-theme($selector: '.theme-principle') {
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @layer base {
    *,
    ::before,
    ::after {
      border-style: none;
    }
  }

  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
  @import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

  @include mat.typography-hierarchy(theme.$typography, $selector);

  #{$selector} {
    @include shared.helper-classes();
    @include theme.principle-material-theme;
    @include theme.material-custom;
    @include theme.mat-title-typography(theme.$title-font-family);
    @include theme.capitalize;
    @include theme.splash-loader;
    @include theme.dc-chart;
    @include theme.json-schema-form;
    @include theme.hotkeys;
    @include theme.clinical-chart;
    @include theme.custom-right-click;
    @include theme.cdk-drag-preview;
    @include theme.intercom;
    @include theme.misc;
    @include theme.treatment;
  }
}
