@use 'scss-shared' as shared;
@use './mixins' as mixins;
@use './variables' as variables;

@mixin dc-chart {
  .dc-chart {
    g.row text {
      fill: #000 !important;
    }

    &.rotate-x-axis-label .axis.x text {
      text-anchor: end !important;
      transform: rotate(-20deg);
    }

    .number-display {
      font-size: 2em;
      text-align: center;
    }

    .dc-select-menu {
      width: 100%;
    }
  }

  .dc-html-legend {
    float: none;
    .dc-legend-item-vertical {
      color: rgba(0, 0, 0, 0.75);

      &.selected {
        background-color: rgba(0, 0, 0, 0);
        color: rgba(0, 0, 0, 1);
        font-weight: bold;
      }
    }
  }

  .d3-tip {
    @include shared.border-radius(2px);
    line-height: 1;
    font-weight: bold;
    padding: 12px;
    background: rgba(255, 255, 255, 0.95);
    color: #000;
    pointer-events: none;
    z-index: 1;
  }

  .d3-tip .tooltip {
    display: block;
    .label {
      color: rgba(0, 0, 0, 0.54);
      padding-bottom: 10px;
    }
    .value {
      color: rgba(0, 0, 0, 0.87);
    }
  }

  /* Creates a small triangle extender for the tooltip */
  .d3-tip:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: rgba(255, 255, 255, 0.8);
    position: absolute;
    pointer-events: none;
  }

  /* Northward tooltips */
  .d3-tip.n:after {
    content: '\25BC';
    margin: -1px 0 0;
    top: 100%;
    left: 0;
    text-align: center;
  }

  /* Eastward tooltips */
  .d3-tip.e:after {
    content: '\25C0';
    margin: -4px 0 0;
    top: 50%;
    left: -8px;
  }

  /* Southward tooltips */
  .d3-tip.s:after {
    content: '\25B2';
    margin: 0 0 1px;
    top: -8px;
    left: 0;
    text-align: center;
  }

  /* Westward tooltips */
  .d3-tip.w:after {
    content: '\25B6';
    margin: -4px 0 0 -1px;
    top: 50%;
    left: 100%;
  }

  /* Chart Menus */
  .chart-selector-menu {
    min-width: 200px !important;
    h2 {
      margin: 0;
      padding: 0 shared.$mat-margin shared.$half-margin;
    }
    .mat-menu-item.selected {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
}
