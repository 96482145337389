@use '@angular/material' as mat;
@use 'scss-shared' as shared;
@use './mixins' as mixins;
@use './colours' as colours;
@use './variables' as variables;

@mixin misc {
  .wrapper {
    @include mixins.page-wrapper();
    padding: (shared.$mat-margin * 2) 0;
    &.content-has-margin {
      padding: shared.$mat-margin 0;
      max-width: variables.$page-wrap + (shared.$mat-margin * 2);
    }
  }

  .selected-route {
    background: rgba(0, 0, 0, 0.04);
  }

  mat-list.notes-list mat-list-item .mat-list-item-content {
    align-items: start !important;
  }

  a[mat-icon-button] {
    line-height: 36px;
  }

  a {
    color: mat.m2-get-color-from-palette(colours.$primary);
    text-decoration: none;
  }

  .mat-mdc-form-field-label {
    color: inherit;
  }

  h2.widget-title {
    padding: 20px 20px 0;
    margin: 0;
  }

  .widget-title {
    padding: 20px 20px 0;
    margin: 0;
  }

  .widget-action {
    padding: 10px 10px 0;
  }

  .widget-title + .mat-card-content {
    padding-top: 10px;
  }

  .truncate {
    @include shared.truncate();
  }

  .treatment-tooltip {
    white-space: pre-line !important;
  }

  .late {
    color: red !important;
  }

  .selectable {
    cursor: pointer;
  }

  .grabbable {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }

  .gu-mirror {
    .grabbable {
      cursor: grabbing;
    }
  }

  .checkbox {
    &.high-priority {
      .mat-checkbox-frame {
        border-color: variables.$high-priority-color;
      }
    }

    &.medium-priority {
      .mat-checkbox-frame {
        border-color: variables.$medium-priority-color;
      }
    }
  }

  mat-icon {
    &.high-priority {
      color: variables.$high-priority-color;
    }

    &.medium-priority {
      color: variables.$medium-priority-color;
    }
  }

  .toolbar-dialog {
    mat-dialog-container {
      padding: 0;
    }
  }

  .mat-toolbar {
    min-height: var(--mat-toolbar-standard-height);
  }

  mat-clock .mat-toolbar {
    min-height: initial;
  }

  .report-card-footer {
    display: block;
    position: relative;
    padding: 24px;
    background: white;
  }

  .mat-sort-header-button {
    text-align: left;
  }

  .fake-icon-button {
    height: 40px;
    width: 40px;
  }

  .actions-card {
    @include shared.border-radius(4px);
    @include mat.elevation(1);
    min-height: 50px;
    background-color: #ffffff;
    button {
      line-height: 50px;
    }
  }

  pt-search-field {
    @include shared.border-radius(4px);
    @include mat.elevation(1);

    &.flat {
      @include mat.elevation(0);
    }
  }

  .elevated {
    .pt-connected-dialog-container {
      @include mat.elevation(5);
    }
  }

  .no-padding {
    .pt-connected-dialog-container {
      padding: 0;
    }
  }

  .connected-dialog-attached-bottom {
    .pt-connected-dialog-container {
      @include mat.elevation(5);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .mat-mdc-form-field.compact {
    .mat-mdc-form-field-bottom-align::before {
      height: 8px;
    }

    .mat-mdc-form-field-hint {
      line-height: 1.8;
    }

    .mat-mdc-form-field-wrapper {
      height: 36px;
      padding-bottom: 0;
      .mat-mdc-form-field-underline {
        bottom: 0;
      }

      &.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 36px;
      }
    }

    .mat-mdc-form-field-infix {
      border-top: 0;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
      .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
        --mat-mdc-form-field-label-transform: translateY(-21.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
      }

      .mat-mdc-form-field-infix {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 36px;
      }
    }

    .mat-mdc-text-field-wrapper
      .mat-mdc-form-field-flex
      .mat-mdc-floating-label {
      font-size: 12px;
      top: 15px;
    }
  }

  .cdk-overlay-pane {
    .wide-backdrop {
      max-width: 500px;
    }
  }

  .date-range-panel {
    height: 380px !important;
  }

  .opaque {
    opacity: 0.5;
  }

  .no-backdrop {
    opacity: 0.5;
  }

  .actionSnackBar {
    button {
      background-color: mat.m2-get-color-from-palette(colours.$primary);
      color: #fff;
    }
    @media print {
      display: none;
    }
  }

  .right-align.mat-mdc-cell,
  .right-align.mat-mdc-footer-cell {
    text-align: right;
    justify-content: flex-end;
    padding-right: 16px;
  }

  .right-align.mat-mdc-header-cell {
    text-align: right;
    justify-content: flex-end;
    padding-right: 0px;
    padding-left: 24px;
  }

  .right-align.mat-mdc-header-cell:last-child {
    margin-right: 0;
  }

  .management-only {
    border: 1px red solid;
    border-radius: 6px;
    padding: 12px 10px 10px;
    position: relative;
    margin: 4px 0 6px;

    &::before {
      content: 'Visible to management only';
      color: red;
      position: absolute;
      top: -10px;
      left: 10px;
      font-size: 10px;
      background: white;
      padding: 0 4px;
    }
  }

  .mat-mdc-form-field tiptap-editor {
    padding-left: 0 !important;
  }
}
