@mixin custom-right-click {
  .pr-right-click-menu {
    &.mat-menu-panel {
      min-height: 50px;
    }
    .mat-menu-item {
      line-height: 34px;
      height: 34px;
    }
  }
}
