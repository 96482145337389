@use '@angular/material' as mat;

@font-face {
  font-family: 'Galano Grotesque';
  src:
    url('/assets/fonts/galano_grotesque_light-webfont.woff2') format('woff2'),
    url('/assets/fonts/galano_grotesque_light-webfont.woff') format('woff'),
    url('/assets/fonts/galano_grotesque_light-webfont.ttf') format('truetype');
}

$title-font-family: 'Galano Grotesque', Roboto, 'Helvetica Neue', sans-serif;
$roboto-font-family: Roboto, 'Helvetica Neue', sans-serif;

$typography: mat.m2-define-typography-config(
  $font-family: $roboto-font-family,
  $headline-1:
    mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2:
    mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3:
    mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.m2-define-typography-level(34px, 40px, 400),
  $headline-5: mat.m2-define-typography-level(24px, 32px, 400),
  $headline-6: mat.m2-define-typography-level(20px, 32px, 500),
  $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400),
  $subtitle-2: mat.m2-define-typography-level(15px, 24px, 400),
  $body-1: mat.m2-define-typography-level(15px, 24px, 400),
  $body-2: mat.m2-define-typography-level(14px, 20px, 400),
  $caption: mat.m2-define-typography-level(12px, 20px, 400),
  $button: mat.m2-define-typography-level(14px, 14px, 500),
);

$regular-font-family: Roboto, 'Helvetica Neue', sans-serif;
$title-font-family: 'Galano Grotesque', $regular-font-family;

@mixin mat-title-typography($font-family, $selector: '.mat-typography') {
  #{$selector} h1,
  #{$selector} h2,
  .mat-headline-1,
  .mat-headline-2,
  .mat-headline-3,
  .mat-headline-4,
  .mat-headline-5,
  .mat-headline-6 {
    font-family: $font-family;
  }
  #{selector} {
    .mat-display-1,
    .mat-typography .mat-display-1 {
      margin-bottom: 20px;
    }
    h5 {
      font-size: 0.8em;
      font-weight: 400;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.4);
      margin: 5px 0;
    }
  }
}

@mixin capitalize {
  .capitalize {
    text-transform: capitalize;
  }
}
