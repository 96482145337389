@use 'sass:map';
@use '@angular/material' as mat;
@use 'scss-shared' as shared;
@use './mixins' as mixins;
@use './variables' as variables;
@use './colours' as colours;

@mixin drag-drop {
  .cdk-drop-list {
    @include shared.border-radius(variables.$drag-item-border-radius);
    border: 1px solid #eee;
    min-height: shared.$mat-margin * 2;
    background-color: #fff;
  }
  .cdk-drag {
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    background-color: #fff;
    padding: 0 shared.$mat-margin;
  }
  .cdk-drag-handle {
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;
  }
  .cdk-drag-placeholder {
    opacity: 0.1;
    background: #ccc;
  }
  .cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

@mixin cdk-drag-preview {
  .cdk-drag-preview {
    @include mat.elevation(9);
    @include shared.border-radius(variables.$drag-item-border-radius);
    box-sizing: border-box;
    padding: 0 shared.$mat-margin;
    background: #fff;
  }
}

@mixin styled-drop-list($border-radius: 4px) {
  .cdk-drop-list {
    border-radius: $border-radius;
    .is-empty-placeholder {
      padding: 8px 16px;
      opacity: 0.5;
    }
    &.cdk-drop-list-dragging {
      border: 2px dashed rgba(0, 0, 0, 0.2);
      .is-empty-placeholder {
        display: none;
      }
    }
  }
  .cdk-drag-preview {
    @include mat.elevation(3);
    border-radius: $border-radius;
    background-color: #fff;
  }
  .cdk-drag-placeholder {
    border: 1px solid #333;
    opacity: 0.1;
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  .cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
  .cdk-drag-animating {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
  }
  .cdk-drop-list-receiving {
    border: 2px dashed rgba(0, 0, 0, 0.2);
  }
}

@mixin drag-handle {
  opacity: 0.25;
}

@mixin form-builder-drop-list {
  &.cdk-drop-list-dragging {
    border-color: map.get(colours.$primary, 400);
  }
  &.cdk-drop-list-receiving {
    border-color: map.get(colours.$primary, 50);
  }
}
