@use 'sass:color';
@use './colours' as colours;

$selected-colour: #f5c000;

@mixin selected-surface {
  stroke: $selected-colour;
  stroke-width: 12px;
}

@mixin disabled-surface {
  fill: #ccc !important;
  opacity: 0.8;
}

@mixin highlighted-surface {
  fill: color.adjust(
    colours.$chart-highlight-colour,
    $lightness: 30%
  ) !important;
}

@mixin clinical-chart {
  .clinical-chart {
    .tooth-outline {
      fill: #172433;
    }
    .surface {
      fill: #ffffff;
      cursor: pointer;
      &.highlighted {
        @include highlighted-surface;
      }
      &.selected .background {
        @include selected-surface;
      }
    }
    .selection-area {
      fill: rgba(colours.$chart-highlight-colour, 0.1);
      stroke: rgba(colours.$chart-highlight-colour, 0.9);
      stroke-width: 3;
    }
    .area-label {
      fill: #fff;
      font-size: 42px;
      alignment-baseline: middle;
    }
  }

  .small-text {
    .clinical-chart {
      .area-label {
        font-size: 30px;
      }
    }
  }

  .hide-tooth-labels {
    .clinical-chart {
      .tooth-label {
        display: none;
      }
    }
  }
}
