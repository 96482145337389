@use 'sass:map';
@use '@angular/material' as mat;
@use './palettes' as palettes;

$primary: mat.m2-define-palette(palettes.$pr-crayola-blue);
$accent: mat.m2-define-palette(palettes.$pr-oxford-blue);
$warn: mat.m2-define-palette(mat.$m2-red-palette);
$success: mat.m2-define-palette(mat.$m2-green-palette);

$resource-colors: ();
$resource-colors: map.merge(
  $resource-colors,
  (
    appointment: mat.$m2-purple-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    appointmentRequest: mat.$m2-purple-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    preBlock: mat.$m2-purple-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    meeting: mat.$m2-blue-grey-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    break: mat.$m2-blue-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    leave: mat.$m2-indigo-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    gap: mat.$m2-deep-orange-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    lab-job: mat.$m2-teal-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    invoice: mat.$m2-green-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    payment: mat.$m2-green-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    task: mat.$m2-amber-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    patient: mat.$m2-light-blue-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    sms: mat.$m2-cyan-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    email: mat.$m2-deep-purple-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    call: mat.$m2-pink-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    note: mat.$m2-indigo-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    clinical-note: mat.$m2-yellow-palette,
  )
);
$resource-colors: map.merge(
  $resource-colors,
  (
    form: mat.$m2-pink-palette,
  )
);

@mixin event-type-color {
  @each $type, $color in $resource-colors {
    &.#{$type} {
      color: map.get($color, 500);
    }
  }
}

@mixin event-type-background-color {
  @each $type, $color in $resource-colors {
    &.#{$type} {
      background-color: map.get($color, 500);
    }
  }
}

$background-color: #fafafa;
$yellow: #f4c018;
$light-grey: #f2f2f2;
$principle-grey: #ddd;
$dark-grey: #5f6368;

$complete: #5ec375;
$unscheduled: #aaa;
$scheduled: #9800ff;
$in-progress: #3772ff;

$gums-light: rgb(200, 157, 168);
$gums-medium-light: rgb(184, 132, 149);
$gums-medium-dark: rgb(185, 125, 142);
$gums-dark: rgb(168, 93, 113);

$chart-highlight-colour: cyan;
