@use 'sass:color';
@use 'sass:math';
@use 'scss-shared' as shared;
@use './mixins' as mixins;
@use './variables' as variables;

$hotkey-color: #aaaaaa;

@mixin hotkeys {
  .cfp-hotkeys-close {
    border: none !important;
    &:hover {
      background-color: inherit !important;
    }
  }

  .hotkey {
    @include shared.border-radius(100px);
    font-size: 0.8em;
    min-width: 16px;
    text-align: center;
    white-space: nowrap;
    display: inline-block;
    padding: 0 math.div(shared.$mat-margin, 4);
    border: 1px solid color.adjust($hotkey-color, $lightness: 25%);
    background-color: color.adjust($hotkey-color, $lightness: 30%);
    color: $hotkey-color;
  }
}
