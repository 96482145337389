@use '@angular/material' as mat;
@use 'scss-shared' as shared;
@use 'sass:map';
@use './variables' as variables;
@use './colours' as colours;

@mixin material-custom {
  .mdc-list-item {
    --mdc-list-list-item-one-line-container-height: 48px;
  }

  // Active icon color in list nav
  mat-nav-list {
    [mat-list-item].active {
      mat-icon[matListItemAvatar] {
        background-color: mat.m2-get_color_from_palette(colours.$primary);
        color: mat.m2-get_color_from_palette(colours.$primary, default-contrast);
      }
      mat-icon[matListItemIcon] {
        color: mat.m2-get_color_from_palette(colours.$primary);
      }
    }
  }

  // mat-tab-group tabs fill available height
  mat-tab-group.full-height {
    .mat-mdc-tab-body-wrapper {
      flex: 1 1 100%;
    }
  }

  .mat-mdc-tab-nav-bar,
  .mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .mat-mdc-tab-group,
  .mat-mdc-tab-list {
    .mat-ink-bar {
      height: 3px;
      background-color: mat.m2-get_color_from_palette(colours.$primary) !important;
    }

    .mat-mdc-tab,
    .mat-mdc-tab-label,
    .mat-mdc-tab-link {
      padding: 0 8px;
      font-size: 16px;

      &.mdc-tab--active {
        .mdc-tab__text-label,
        .mdc-tab-indicator__content--underline {
          color: mat.m2-get_color_from_palette(colours.$primary) !important;
        }
      }

      .mdc-tab__text-label {
        position: relative;
        > div.has-notification {
          background: #f44336;
          width: 10px;
          height: 10px;
          border-radius: 5px;
          position: absolute;
          top: -5px;
          right: -5px;
        }

        > span + mat-icon.has-notification {
          position: absolute;
          top: -8px;
          right: -24px;
          @include shared.mat-icon-size(18px);
        }

        > mat-icon.has-notification {
          position: absolute;
          top: -8px;
          right: -8px;
          @include shared.mat-icon-size(18px);
        }
      }
    }

    &.mat-mdc-tab-icon-labels {
      .mat-mdc-tab-label,
      .mat-mdc-tab-link {
        min-width: 60px;
      }
    }
  }

  // NoStyle Input Field Component
  .mat-mdc-form-field-prefix,
  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-infix,
  .mat-mdc-form-field-suffix,
  .mat-mdc-form-field-icon-suffix {
    display: inline-flex;
    align-items: center;

    .mat-mdc-icon-button {
      &.mat-mdc-button-base {
        --mdc-icon-button-icon-size: 24px;

        .mat-icon {
          width: 24px;
          height: 24px;
          font-size: 24px;
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .mat-mdc-form-field-hint-wrapper {
    color: rgba(0, 0, 0, 0.54);
    font-size: 10.5px;
  }

  mat-form-field {
    &.no-style {
      .mat-mdc-form-field-wrapper {
        padding-bottom: 0;
        .mat-mdc-form-field-flex {
          align-items: center;
          .mat-mdc-form-field-prefix,
          .mat-mdc-form-field-icon-prefix,
          .mat-mdc-form-field-infix,
          .mat-mdc-form-field-suffix,
          .mat-mdc-form-field-icon-suffix {
            padding: 10px 5px;
            border-top: 0;
          }
        }
      }
      .mat-mdc-form-field-underline {
        bottom: 0;
      }
      &.mat-mdc-form-field-should-float {
        .mat-mdc-form-field-label {
          display: none;
        }
      }
    }
    &.no-underline {
      .mat-mdc-form-field-underline {
        display: none;
      }
    }
    &.label-wrapper-prefix {
      .mat-mdc-form-field-label-wrapper {
        margin-top: 4px;
      }
    }
  }

  mat-accordion {
    --mat-expansion-header-collapsed-state-height: 70px;
    --mat-expansion-header-expanded-state-height: 70px;

    &.no-content-padding {
      .mat-expansion-panel-body {
        padding: 0;
      }
    }
    &.popout {
      mat-expansion-panel {
        &.mat-expanded {
          margin-top: 50px;
          margin-bottom: 50px;
          margin-left: -(shared.$mat-margin);
          margin-right: -(shared.$mat-margin);
        }
      }
    }
  }

  mat-card {
    &.no-padding {
      padding: 0;
    }
  }

  .mat-mdc-snack-bar-container
    .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: mat.m2-get_color_from_palette(colours.$primary);
  }

  .mdc-dialog__title + .mdc-dialog__content,
  .mdc-dialog__header + .mdc-dialog__content {
    padding-top: 20px;
  }

  .mat-mdc-dialog-actions {
    display: flex;
    justify-content: flex-end;
    padding: 16px 25px;
  }

  .mat-optgroup-label {
    line-height: 48px;
  }

  .mat-mdc-option {
    font-size: 14px;
  }

  mat-optgroup.dense {
    .mat-optgroup-label {
      height: 24px;
      line-height: 24px;
      font-size: 12px;
    }
    mat-option {
      @include dense-mat-option;
    }
  }

  mat-option.dense {
    @include dense-mat-option;
  }

  .mat-mdc-menu-panel {
    &.no-padding {
      .mat-mdc-menu-content {
        padding: 0;
      }
    }
    &.wide {
      max-width: 350px;
    }
  }

  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button,
  .mat-mdc-outlined-button,
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    &.mat-mdc-button-base {
      min-height: 36px;
      height: inherit;
    }
    &.dense {
      font-size: 12px;
      line-height: 24px;
      padding: 0 8px;
      min-height: 30px;
    }
  }

  .mat-mdc-button-base {
    > div.has-notification {
      background: #f44336;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      position: absolute;
      top: 1px;
      right: 7px;
    }

    > .mdc-button__label > div.has-notification {
      background: #f44336;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      position: absolute;
      top: -15px;
      right: -3px;
    }
  }

  .mat-mdc-icon-button {
    transition: 200ms ease-in;
    &:hover {
      opacity: 1;
      color: mat.m2-get_color_from_palette(colours.$primary);
    }
  }

  .mdc-list-item--with-trailing-meta.mdc-list-item--with-two-lines
    .mdc-list-item__end,
  .mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines.mdc-list-item--with-trailing-meta
    .mdc-list-item__end {
    display: flex;
    align-self: center;
  }

  .mdc-list-item.mdc-list-item--with-two-lines .mdc-list-item__start {
    align-self: center;
    display: flex;
    align-items: center;
    margin-top: 0px;
  }

  .mdc-notched-outline > * {
    border-color: #ddd !important;
  }

  .mat-vertical-content {
    padding: 24px;
  }

  .mat-mdc-dialog-content {
    max-height: 80vh;
  }

  .mdc-data-table__row .mdc-data-table__cell {
    border-bottom: solid 1px rgba(0, 0, 0, 0.12) !important;
  }

  .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary {
    display: flex;
  }

  .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
  .mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
  .mat-mdc-standard-chip .mat-mdc-chip-action-label {
    overflow: hidden;
  }

  .mdc-evolution-chip__text-label {
    text-overflow: clip;
  }

  button.mat-mdc-icon-button {
    &.icon-sm {
      @include shared.mat-icon-button-size(20px, 0px);
    }
    &.icon-md {
      @include shared.mat-icon-button-size(24px, 0px);
    }
    &.icon-lg {
      @include shared.mat-icon-button-size(32px, 0px);
    }
  }

  mat-icon.mat-icon {
    &.icon-3,
    &.icon-xxs {
      @include shared.mat-icon-size(12px);
      line-height: 12px;
    }
    &.icon-4,
    &.icon-xs {
      @include shared.mat-icon-size(16px);
      line-height: 16px;
    }
    &.icon-5,
    &.icon-sm {
      @include shared.mat-icon-size(20px);
      line-height: 20px;
    }
    &.icon-6,
    &.icon-md {
      @include shared.mat-icon-size(24px);
      line-height: 24px;
    }
    &.icon-7 {
      @include shared.mat-icon-size(28px);
      line-height: 28px;
    }
    &.icon-lg,
    &.icon-8 {
      @include shared.mat-icon-size(32px);
      line-height: 32px;
    }
  }

  mat-timepicker-dialog {
    width: 100%;
    display: block;
    overflow: hidden;

    .mat-mdc-dialog-content {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

@mixin dense-mat-option {
  height: 36px;
  font-size: 13px;
}
