@use 'sass:map';
@use './mixins' as mixins;
@use './colours' as colours;
@use './theme' as theme;

@mixin splash-loader {
  .pr-app {
    width: 100%;
    height: 100%;
  }

  .principle-splash-loader {
    @include mixins.cover-screen();
    @include mixins.centered-content();
    @include mixins.principle-watermark();
    @include theme.bg-gradient();

    img.logo {
      width: 200px;
    }

    .loader {
      position: absolute !important;
      bottom: 0;
      height: 5px;
      width: 100%;
      @include mixins.loading-bar(map.get(colours.$accent, 500));
    }
  }
}
