@use 'sass:color';
@use 'sass:map';
@use '@angular/material' as mat;
@use 'scss-shared' as shared;
@use './variables' as variables;
@use './colours' as colours;
@use './typography' as typography;

@mixin page-wrapper {
  max-width: variables.$page-wrap;
  margin: 0 auto;
}

@mixin patient-summary-sidebar {
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  overflow: hidden;
  .steps {
    flex: 1 1 auto;
    overflow-y: scroll;
  }
}

@mixin unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  // Soon to be implemented
  -o-user-select: none;
  user-select: none;
}

@mixin drop-zone {
  @include shared.border-radius(variables.$drag-item-border-radius);
  border: 2px dashed #eee;
}

@mixin small-icon-button {
  @include shared.mat-icon-button-size(20px, 0px);
  opacity: 0.5;
}

@mixin medium-icon-button {
  @include shared.mat-icon-button-size(24px, 0px);
}

@mixin small-mat-chip {
  .mat-mdc-chip.mat-mdc-standard-chip {
    --mdc-chip-label-text-size: 10px;
    --mdc-chip-container-height: 22px;
  }
}

@mixin subtle-text-link {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.25);
  text-decoration: underline;
  font-size: 0.8em;
}

@mixin principle-watermark {
  background-image: url(/assets/icons/principle-logo-watermark.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 500px auto;
}

@mixin cover-screen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@mixin centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin loading-bar($color) {
  position: relative;
  overflow: hidden;
  background-color: $color;
  &:before {
    display: block;
    position: absolute;
    content: '';
    left: -200px;
    width: 200px;
    height: 100%;
    background-color: color.adjust($color, $saturation: 20%);
    animation: loading 2s linear infinite;
  }
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }
  50% {
    width: 30%;
  }
  70% {
    width: 70%;
  }
  80% {
    left: 50%;
  }
  95% {
    left: 120%;
  }
  to {
    left: 100%;
  }
}

@mixin info-panel {
  @include shared.layout-margin;
  h3 {
    margin: 0;
  }
  p {
    margin: 0;
  }
}

@mixin rounded-card {
  @include shared.border-radius(4px);
  @include mat.elevation(1);
  background-color: #fff;
}

@mixin pt-collapsible-header {
  cursor: pointer;
  background: #f5f5f5;
}

@mixin breadcrumbs-theme($theme) {
  pt-breadcrumbs {
    $primary: map.get($theme, primary);
    padding: 0 shared.$mat-margin;

    .mat-headline-6 {
      font-family: typography.$regular-font-family;
      font-size: 0.9em !important;
    }

    a {
      color: mat.m2-get_color_from_palette($primary, 400);
      &:hover {
        color: mat.m2-get_color_from_palette($primary, 500);
      }
    }

    .breadcrumbs-wrapper {
      padding: 0 10px;
    }
  }
}

@mixin mat-list-item-height($height) {
  height: $height;
  .mat-list-item-content {
    height: $height;
  }
}

@mixin placeholder-light {
  &::placeholder {
    color: colours.$principle-grey;
  }
}

@mixin placeholder-dark {
  &::placeholder {
    color: colours.$dark-grey;
  }
}

@mixin custom-input($padding: 2px 6px) {
  @include shared.border-radius(4px);
  @include placeholder-dark;
  border: 1px solid colours.$principle-grey;
  padding: $padding;

  &:focus {
    outline-color: colours.$principle-grey;
  }
}

.custom-input-default {
  @include custom-input;
}
